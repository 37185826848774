<template>
  <div id="app">
    <BlockUI :message="msg" v-if="showloader">
      <img src="@/assets/img/loader.svg" style="text-align: center" alt="" />
    </BlockUI>
    <header-section></header-section>
    <router-view />
    <footer-section></footer-section>
  </div>
</template>

<script>
import HeaderSection from "@/components/common/header-section";
import FooterSection from "@/components/common/footer-section";

export default {
  components: { FooterSection, HeaderSection },
  data() {
    return {
      appName: this.$func.appname,
      appVersion: this.$func.appversion,
      companyName: this.$func.companyname,
      msg: "Loading... Please wait...",
      showloader: false,
    };
  },
  /*beforeCreate() {
    const _vm = this;
    _vm.$router.push({ path: "/" });
  },*/
  created() {
    const _vm = this;
    _vm.$axios.interceptors.request.use(
      (config) => {
        _vm.showloader = true;
        return config;
      },
      (error) => {
        _vm.showloader = false;
        return Promise.reject(error);
      }
    );
    _vm.$axios.interceptors.response.use(
      (response) => {
        _vm.showloader = false;
        return response;
      },
      (error) => {
        _vm.showloader = false;
        return Promise.reject(error);
      }
    );
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      document.title = to.meta.title + " - " + this.appName;
    },
  },
};
</script>

<style>
body {
  background-image: url("assets/img/background.jpg");
  background-color: #ffffff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Oswald" !important;
  /*height: 100%;*/
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "Oswald" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*height: 100%;
  position: fixed;
  width: 100%;*/
}
/*@media (max-width: 560) {
  #app {
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
  }
}
@media (max-width: 414) {
  #app {
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: scroll;
  }
}*/
.meter {
  height: 20px;
  position: relative;
  margin: 60px 0 20px 0;
  background: #ffe66d;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 10px;
  -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(43, 194, 83)),
    color-stop(1, rgb(84, 240, 84))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    0 0,
    100% 100%,
    color-stop(0.25, rgba(255, 255, 255, 0.2)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, rgba(255, 255, 255, 0.2)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  -webkit-background-size: 50px 50px;
  -moz-background-size: 50px 50px;
  -webkit-animation: move 2s linear infinite;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}
.animate > span:after {
  display: none;
}
@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.orange > span {
  background-color: #f1a165;
  background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f1a165),
    color-stop(1, #f36d0a)
  );
  background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}
.red > span {
  background-color: #f0a3a3;
  background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f0a3a3),
    color-stop(1, #f42323)
  );
  background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}
.nostripes > span > span,
.nostripes > span:after {
  -webkit-animation: none;
  background-image: none;
}
.table-earnings {
  background: #3034;
  color: #b7b8c0;
}
.table {
  display: block;
  overflow-y: auto;
  color: #1c203a;
  height: 264px;
}
.tablenew {
  width: 15%;
  font: 11px;
}
.tablenew1 {
  width: 14%;
  font: 11px;
}
.yaxistext {
  color: #bebfc6;
}
.timelinebackground {
  background: #46495e;
  text-align: center;
}
.timelinebackground1 {
  background: #fff;
  text-align: center;
}
.timelinebackground2 {
  background: #9697a3;
  text-align: center;
}
.timelinebackground3 {
  background: #bebfc6;
  text-align: center;
}
.gaphead {
  padding-top: 5%;
}
</style>
