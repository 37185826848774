export const func = {
  loginUrl: "https://bgsw1.sharepoint.com/sites/CONNECTII",
  companystartyear: "2011",
  appname: "Connect",
  appversion: "2.0",
  companyname: "Suntory Global Spirits",
  alerticon: {
    success: "success",
    error: "error",
    info: "info",
    warning: "warning",
    question: "question",
  },
  usertype: {
    default: 0,
    distributor: 1,
    beamSuntoryContractor: 2,
    tradePartner: 3,
    promoAgency: 4,
  },
  country: [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, the former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States Minor Outlying Islands",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  states: {
    Canada: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewans",
    ],
    "United States": [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Mont. Cty.",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Ohio - High Proof",
      "Ohio - Low Proof",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "Washington DC",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  region: ["Americas", "International"],
  commRegion: [
    "SWS Region",
    "Midsouth Region",
    "Franchise & Control Region",
    "Central Region",
    "BSI Corporate",
    "SGWS West Region",
    "SGWS Central Region",
    "SGWS East Region",
    "RNDC Region",
    "Franchise and Control States Region",
    "Natl-CatMan",
  ],
  divisions: {
    "SWS Region": [
      "CA/HI Division",
      "Mountain Division",
      "Central Division",
      "East Coast Division",
      "Florida Division",
    ],
    "Midsouth Region": [],
    "Franchise & Control Region": [
      "Franchise Region",
      "Eastern Control",
      "Western Control",
    ],
    "Central Region": [],
    "BSI Corporate": [],
    "SGWS West Region": ["California/Hawaii Division", "Mountain Division"],
    "SGWS Central Region": [],
    "SGWS East Region": [
      "Florida Division",
      "New York Division",
      "Mid-Atlantic Division",
    ],
    "RNDC Region": [],
    "Franchise and Control States Region": [
      "Franchise Region",
      "Eastern Control Division",
      "Western Control Division",
    ],
    "Natl-CatMan": ["Natl Accounts-Off", "Natl Accounts-On", "Category Mngmt"],
  },
  areas: {
    Texas: [
      "Dallas/Ft Worth",
      "Austin/San Antonio",
      "Houston/South TX",
      "Chain Sales",
      "Inside Sales",
    ],
    "New York": ["UNY", "MNY", "Inside Sales"],
    Florida: ["NFL", "SFL", "Chains", "Inside Sales", "Chain Sales"],
    California: ["NCA", "SCA", "Chain Sales", "Inside Sales"],
    Nebraska: ["Inside Sales", "General Sales"],
    Illinois: ["Inside Sales", "General Sales"],
    Minnesota: ["Inside Sales", "General Sales"],
    Louisiana: ["Inside Sales", "General Sales"],
    Arizona: ["Inside Sales", "General Sales"],
    "Ohio - Low Proof": ["Low Proof"],
    "Ohio - High Proof": ["High Proof"],
  },
  employers: {
    Dist_ID: [
      "A&E",
      "A BRAND NEW DAY",
      "ABV BRANDS",
      "ACTIVE BRANDS PORTUGAL",
      "ALLAN S. GOODMAN, INC.",
      "ALLIED BEVERAGE, LLC",
      "ALVAREZ Y SANCHEZ",
      "AMBER BEVERAGE GROUP",
      "AMCO",
      "AMYVYX SA GREECE",
      "ANTILLIAN",
      "ASC FINE WINES",
      "ASIA EUROWINES",
      "ATHENS DISTRIBUTING CO.",
      "ATLANTIC GRUPA",
      "ATLAS SOFTWARE TECHNOLOGIES",
      "B.FERNANDEZ",
      "B & T DISTRIBUTING CO.",
      "BACARDI",
      "BADGER LIQUOR CO.",
      "BAHAMIAN BREWERY AND BEVERAGE",
      "BALLESTER",
      "BARRY & FITZWILLIAM",
      "BEAM SUNTORY CONTRACTOR",
      "BEST BRANDS, INC.",
      "BINH MING FOOD",
      "BREAKTHRU BEVERAGE",
      "BRESCOME BARTON INC.",
      "BRISTOL CELLARS",
      "BRYDENS",
      "BSI",
      "BT TURKEY",
      "BUTLER AND SANDS",
      "CAMPARI",
      "CARAVAN",
      "CASALBOR",
      "CATALYST",
      "CAYMAN DISTRIBUTORS LTD",
      "CC1",
      "CCA",
      "CENTRAL DISTRIBUTORS INC.",
      "CENTUM",
      "CHOKO (SRT)",
      "CINCO SENTIDOS",
      "CINOCO",
      "CLT",
      "CONNECTICUT DISTRIBUTORS, INC.",
      "CONVIVIALITY",
      "COOPER HOUSE ASIA PTE LTD",
      "CRISTALEX",
      "D&V DISTRIBUTING",
      "DADSONS UK LTD",
      "DELTA WHOLESALE LIQUORS",
      "DESTILERIA SERRALES",
      "DIRECT",
      "DISCOUNT LIQUOR",
      "DYNAMIKI_SA.",
      "EDER BROTHERS, INC.",
      "EDRINGTON FIX MIDDLE EAST",
      "EDRINGTON NORDIC",
      "EF - DUNHAM & SMITH",
      "EKULO INTERNATIONAL LTD",
      "EL CATADOR",
      "EMPIRE DISTRIBUTORS",
      "FEDWAY ASSOCIATES",
      "GENERAL WHOLESALE CO. - ATLANTA",
      "GEORGIA CROWN DISTRIBUTING CO.- MCDONOUGH",
      "GLOBAL SPIRITS CZECH",
      "GOSLING",
      "GWS",
      "HACAREM SPIRITS LTD",
      "HAECKY SWISS",
      "HARTLEY & PARKER LTD., INC.",
      "HAUGEN GRUPPEN",
      "HEINEMANN",
      "HORIZON BEVERAGE COMPANY",
      "HORIZON WINE & SPIRITS",
      "INDEPENDENT WINE & SPIRITS CO LTD",
      "IDS",
      "ITALMARKET",
      "JACQUES SCOTT LTD",
      "JOHNSON BROTHERS WHOLESALE LIQUORS",
      "KANTI",
      "KING BEVERAGE",
      "LA MADRILENA",
      "M.S. WALKER, INC.",
      "MABAT",
      "MAJOR BRANDS",
      "MARITIME & MERCANTILE INTERNATIONAL LLC",
      "MARTINI & ROSSI ITALY",
      "MAXXIUM",
      "MENDEZ AND COMPANY",
      "MER ET SOLEIL AGENCY",
      "MERITUS PRIME DISTRIBUTIONS INC",
      "MINERALINIAI VANDENYS",
      "MOON DISTRIBUTING",
      "NOAH GROUP",
      "NORTON",
      "OPUS ONE",
      "PEPIA",
      "PJSC BREST BAKALEYA",
      "PLAZA PROVISION",
      "PREMIER/GLAZERS",
      "PRIME",
      "PT. PANCANIAGA INDOPERKASA",
      "R&R",
      "RAIMBEK",
      "REMY COINTREAU",
      "RFD",
      "RNDC",
      "ROADTOWN",
      "SELECT BRANDS",
      "SIA DIAKONIA",
      "SOUTHERN GLAZER'S WINE & SPIRITS",
      "SOUTHWESTERN",
      "STANSFIELD SCOTT",
      "STAR",
      "STANDARD BEVERAGE",
      "STOCK CROATIA",
      "STOCK POLSKA SP. Z O.O",
      "STOCK SLOVENSKO",
      "SUNBO LIQUOR TRADING CO., LTD",
      "SUNTORY",
      "THE BRISTOL GROUP",
      "TRIPLE C DISTRIBUTING CO.",
      "TUDOR HOUSE LTD SP. Z O.O",
      "UBFB",
      "UNITED",
      "UNITED DISTRIBUTING INC.",
      "V.SUAREZ",
      "VICENTI GROUP",
      "VINFORT",
      "VINOS SA",
    ],
    Trade_ID: [
      "1000 GROUP",
      "AAFES",
      "ALBERTSONS",
      "ALCOOL NB LIQUOR",
      "APPLEBEE'S",
      "ARIA RESORT AND CASINO",
      "BAD MONKEY",
      "BIG BEAT DALLAS @@ THE MUSIC FACTORY",
      "BEAU RIVAGE",
      "BLUE PLATE GROUP",
      "BRAND BUILDERS",
      "BRAND MOMENTUM",
      "CAESAR'S ENTERTAINMENT",
      "CASHWISE/COBORN'S",
      "CHARCOAL GROUP",
      "CHILIS",
      "COPPER MOUNTAIN",
      "CORONA CIGAR COMPANY",
      "COSMOPOLITAN LAS VEGAS",
      "DARDEN RESTAURANTS",
      "DAVE & BUSTERS",
      "DAVIDSON HOTELS",
      "DIAMOND GIRL PROMOTIONS",
      "DOE-ANDERSON",
      "DOGWOOD SOUTHERN TABLE & BAR",
      "EL CORTEZ HOTEL & CASINO",
      "FIU",
      "FOSSIL CREEK LIQUOR",
      "GEOMETRY GLOBAL",
      "GLAMOUR PROMOTIONS INC.",
      "GLORY DAYS GRILL",
      "HARRAHS CASINO LAS VEGAS",
      "HY-VEE",
      "INSPIRA MARKETING GROUP, LLC",
      "INVISION MARKETING",
      "KNIGHTLY SPIRITS",
      "KROGER",
      "LEUKENS",
      "LIDWICK GROUP",
      "LINQ HOTEL AND CASINO",
      "LIQUOR CONTROL BOARD OF ONTARIO",
      "MANDALAY BAY RESORT & CASINO",
      "MARINE CORPS EXCHANGE",
      "MARRIOTT",
      "MGM GRAND HOTEL & CASINO LAS VEGAS",
      "MIRAGE HOTEL & CASINO",
      "MIRUS PROMOTIONS",
      "NEWFOUNDLAND LIQUOR COMMISSION",
      "NOVA SCOTIA LIQUOR COMMISSION",
      "NOW PROMOTIONAL MODELS",
      "OGILVYACTION",
      "OLD CROW",
      "ONLY THE BEST MODELS",
      "PATRICK HENRY CREATIVE PROMOTIONS",
      "PAT'S LIQUOR",
      "PEI LIQUOR COMMISSION",
      "PENNSYLVANIA LIQUOR CONTROL BOARD",
      "PREMIER WINES",
      "PRIMUM PROMOTIONS",
      "PURE PREMISE",
      "SAMCO",
      "SLS HOTEL AND CASINO",
      "SMITH & WOLLENSKY",
      "SPIRE HOSPITALITY",
      "TASTEMAKERS INTERNATIONAL",
      "TAVERN RESTAURANT GROUP",
      "THE COWBOYS CLUB @@ THE STAR",
      "THE D CASINO AND HOTEL",
      "THE OUTPOST TAPHOUSE",
      "THE PUB",
      "TURTLE CREEK CASINO & HOTEL",
      "WALTON ISAACSON",
      "WINE WORLD",
      "WINGHART'S BURGERS",
      "WYNN & ENCORE HOTEL & CASINO",
      "OTHER",
    ],
    Agency_ID: [],
  },
  onOffPermise: ["On", "Off", "Both"],
  msUserStatus: { Activation: 1, Deactivation: 2, Updation: 3 },
  capitalizeFirstLetter: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  addDate: (dt, amount, dateType) => {
    switch (dateType) {
      case "days":
        return dt.setDate(dt.getDate() + amount) && dt;
      case "weeks":
        return dt.setDate(dt.getDate() + 7 * amount) && dt;
      case "months":
        return dt.setMonth(dt.getMonth() + amount) && dt;
      case "years":
        return dt.setFullYear(dt.getFullYear() + amount) && dt;
    }
  },
  validateEmail: (input) => {
    if (input !== undefined && input !== null && input.length > 0) {
      //const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      /*const validRegex =
        /^[a-z0-9](?!.*?[^\na-z0-9]{2})[^\s@]+@[^\s@]+\.[^\s@]+[a-z0-9]$/;*/
      const validRegex =
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/;
      if (
        input.toLowerCase().match(validRegex) !== undefined &&
        input.toLowerCase().match(validRegex) !== null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  validateEmailIsNotInternal: (input) => {
    if (input !== undefined && input !== null && input.length > 0) {
      if (input.toLowerCase().includes("@beamsuntory.com")) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
  validateBeamSuntorySponsorEmail: (input) => {
    if (input !== undefined && input !== null && input.length > 0) {
      if (input.toLowerCase().includes("@beamsuntory.com")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  validateSpecialCharactersForEmployer: (input) => {
    if (input !== undefined && input !== null && input.length > 0) {
      const symbols = ["B. ä", "ä", "ü", "ö", "B ? . &", "?"];
      let hasNotFoundSymbol = true;
      for (let i = 0; i < symbols.length; i++) {
        if (input.includes(symbols[i])) {
          hasNotFoundSymbol = false;
          break;
        }
      }
      return hasNotFoundSymbol;
    } else {
      return false;
    }
  },
  itemExistsInArray: (arr, obj) => {
    return (
      arr !== undefined &&
      arr !== null &&
      arr.length > 0 &&
      arr.indexOf(obj) != -1
    );
  },
  sAlert: (title, icon, text, swalobj) => {
    return swalobj.fire({
      title: title,
      text: text,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  },
  sConfirm: (title, icon, text, confirmtext, swalobj) => {
    return swalobj.fire({
      title: title,
      text: text,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      confirmButtonColor: "#ed7d31",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmtext,
    });
  },
};
