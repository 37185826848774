<template>
  <header class="header fixedhead">
    <nav class="navbar nav-shadow" style="padding: 10px 0 0 15px">
      <div class="search-box">
        <button class="dismiss"><i class="icon-close"></i></button>
        <div id="searchForm" role="search">
          <input
            type="search"
            placeholder="What are you looking for..."
            class="form-control"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div
          class="navbar-holder d-flex align-items-center justify-content-between"
        >
          <div class="navbar-header">
            <router-link
              to="/"
              class="navbar-brand d-none d-sm-inline-block"
              exact
            >
              <div class="brand-text d-none d-lg-inline-block">
                <img src="@/assets/img/connect_logo.png" height="50" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "header-section",
};
</script>

<style scoped>
.fixedhead {
  position: fixed;
  width: 100%;
  z-index: 100000;
}
.nav-shadow {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}
#profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #512da8;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 40px;
  display: inline-block;
}
</style>
