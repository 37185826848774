export const de = {
  fields: {
    country: "Land",
    state: "Bundesland",
    area: "Bereich",
    email: "E-Mail Addresse",
    password: "Passwort",
    verifypassword: "Passwort wiederholen",
    firstname: "Vorname",
    lastname: "Nachname",
    birthdate: "Geburtsdatum",
    sponseremail: "E-Mail von Ihrem Beam Suntory Kontakt",
    brand: "Marke",
    premise: "OFF-/ON-Trade",
    employer: "Auftraggeber",
  },
  values: {
    onOffPermiseVals: [
      { text: "On", value: "On" },
      { text: "Off", value: "Off" },
      { text: "Beide", value: "Both" },
    ],
  },
  messages: {
    mandatoryfield: {
      title: "Pflichtfelder müssen ausgefüllt werden",
      description:
        "Alle Felder sind Pflichtfelder. Bitte füllen Sie vor dem Absenden alle Felder aus.",
    },
    firstnameempty: "Pflichtfeld",
    lastnameempty: "Pflichtfeld",
    validcountry: "Pflichtfeld",
    validstate: "Pflichtfeld",
    validarea: "Pflichtfeld",
    validemail: "Geben Sie eine gultige E-Mail-Adresse ein",
    invalidinternalmail:
      "Interne / Auftragnehmer müssen sich nicht bei CONNECT registrieren",
    validbrand: "Pflichtfeld",
    validemployer:
      "Bitte nennen Sie uns Ihren Arbeitgeber. Dieses Feld darf keine Umlaute enthalten wie z. B. ä, ü, ö und auch keine Satzzeichen wie z. B. ? . &",
    validemailsuggestion:
      "Stellen Sie sicher, dass die E-Mail-Adresse, mit der Sie sich registrieren, gültig ist, da Sie sich sonst möglicherweise nicht damit bei CONNECT anmelden können.",
  },
  userType:{
    selectusertypetext:"Bitte den Benutzertyp auswählen",
    distributor:"Distributeur",
    tradepartner:"Handelspartner",
    creativeagency:"Kreativ-Agentur",
    promoagency: "Promotion-Agentur",
    beamsuntorycontractor:"Beam Suntory Vertragspartner"
  }
};
