export const en = {
  fields: {
    country: "Country",
    state: "State",
    area: "Area",
    email: "Email Address",
    password: "Password",
    verifypassword: "Verify Password",
    firstname: "First Name",
    lastname: "Last Name",
    birthdate: "Birthdate",
    sponseremail: "Beam Suntory Sponsor Email",
    brand: "Brand",
    premise: "On Off Premise",
    employer: "Employer",
  },
  values: {
    onOffPermiseVals: [
      { text: "On", value: "On" },
      { text: "Off", value: "Off" },
      { text: "Both", value: "Both" },
    ],
  },
  messages: {
    mandatoryfield: {
      title: "Mandatory Fields need to be filled",
      description:
        "All fields are mandatory. Please complete all fields before submit.",
    },
    firstnameempty: "Please provide us with your first name",
    lastnameempty: "Please provide us with your last name",
    validcountry: "Please provide us with your country",
    validstate: "Please provide us with your state",
    validarea: "Please provide us with your area",
    validemail: "Please enter a valid email address",
    invalidinternalmail:
      "Internals / Contractors are not required to register at CONNECT",
    validbrand: "Please enter a valid brand",
    validemployer:
      "Please provide us with your employer. This field must not contain any umlauts such as B. ä, ü, ö and also no punctuation such as B ? . &",
    validemailsuggestion:
      "Make sure the email with which you are registering is a valid one, else you may not be able to login CONNECT with it.",
  },
  userType:{
    selectusertypetext:"Please Select Your User Type",
    distributor:"Distributor",
    tradepartner:"Trade Partner",
    creativeagency:"Creative Agency",
    promoagency: "Promo Agency",
    beamsuntorycontractor:"Beam Suntory Contractor"
  }
};
